import { Turbo } from "@hotwired/turbo-rails";

Turbo.session.drive = false;

(() => {
  const bindTrackingEvents = () => {
    Domestika.startGlobalEventsTracker();
  };

  document.addEventListener("turbo:frame-load", () => {
    bindTrackingEvents();
  });

  document.addEventListener("turbo:before-stream-render", (event) => {
    const fallBackRender = event.detail.render;
    // eslint-disable-next-line no-param-reassign
    event.detail.render = (streamElement) => {
      fallBackRender(streamElement);
      bindTrackingEvents();
    };
  });
})();
